import React, {useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';

import {nullCheck} from 'utils/div';
import {DATE_FORMATS, getDateByCustomFormat} from 'utils/date';
import {Column, Row, UserInfo} from './styled';
import {Divider, IconButton, Layout, Text} from '@phonectas/unifon-ui-kit';
import TemplateStatusColor from 'common/components/TemplateStatusColor';
import {useCopyToClipboard} from 'common/hooks/useCopyToClipboard';
import NewCopyIcon from 'common/components/icons/NewCopyIcon';
import DataUsage from 'common/components/DataUsage';
import TrustpilotDialog from 'common/components/TrustpilotDialog';
import UnifonStarIcon from 'common/components/icons/UnifonStarIcon';
import {StyledTrustpilotButton} from 'common/components/styled';


const UserInfoList = ({data}) => {
	const {t} = useTranslation();
	// eslint-disable-next-line no-unused-vars
	const [isCopied, copyToClipboard] = useCopyToClipboard();
	const handleCopyNumber = useCallback(() => {
		copyToClipboard(data?.phone_number);
	}, [copyToClipboard, data?.phone_number]);
	const isWarning = typeof data?.note === 'string' && data.note.trim() !== '';
	const [showTrustpilot, setShowTrustpilot] = useState(false);
	const onHideTrustpilot = useCallback(() => {
		setShowTrustpilot(false);
	}, []);

	const handleShowTrustpilot = useCallback(() => {
		setShowTrustpilot(true);
	}, []);

	const trustpilotBtn = useMemo(() => (
		<StyledTrustpilotButton
			onClick={handleShowTrustpilot}
			icon={<UnifonStarIcon />}
			color="primary"
			variant="action"
			style={{maxHeight: '21px'}}
		>
			{t('Send request')}
		</StyledTrustpilotButton>
	), [handleShowTrustpilot, t]);

	const column1Fields = useMemo(() => [
		{label: t('Phone'), value: (
			<Layout gap="md" fullWidth nowrap alignItems="center">
				<Text variant="body1">{nullCheck(data?.phone_number)}</Text>
				<IconButton onClick={handleCopyNumber} compact $sizePx={19}>
					<NewCopyIcon />
				</IconButton>
			</Layout>
		)},
		{label: t('Email'), value: nullCheck(data?.email)},
		{label: t('Trustpilot'), value: trustpilotBtn},
		{label: t('Status'), value: <TemplateStatusColor statusCode={data?.status} />},
		{label: t('PIN'), value: nullCheck(data?.pin)},
		{label: t('PUK'), value: nullCheck(data?.puk)},
		{label: t('SIM'), value: nullCheck(data?.icc)},
		{label: t('SIM type'), value: nullCheck(data?.sim_type)},
	], [data, t, handleCopyNumber]);

	const column2Fields = useMemo(() => [
		{label: t('Start date'), value: getDateByCustomFormat(data?.start_date, DATE_FORMATS.DD_MM_YYYY)},
		{
			label: t('Porting date'),
			value: data?.port_date ?
				getDateByCustomFormat(data?.port_date, DATE_FORMATS.DD_MM_YYYY_HH_mm) :
				getDateByCustomFormat(data?.start_date, DATE_FORMATS.DD_MM_YYYY),
		},
		{label: t('Binding'), value: getDateByCustomFormat(data?.lockin_end, DATE_FORMATS.DD_MM_YYYY)},
		{label: t('Product'), value: nullCheck(data?.subscription_type)},
		{label: t('Unit'), value: nullCheck(data?.unit)},
	], [data, t]);

	return (
		<UserInfo style={{width: '100%'}}>
			<Row>
				<Column>
					{column1Fields.map((field, index) => (
						<Layout gap="md" fullWidth nowrap key={index}>
							<Layout fullWidth nowrap style={{maxWidth: '110px'}}>
								<Text variant="body1" color="textSecondary">
									{field.label}
								</Text>
							</Layout>
							<Layout fullWidth nowrap>
								<Text variant="body1">{field.value}</Text>
							</Layout>
						</Layout>
					))}
				</Column>
				<Divider vertical fullHeigth fullWidth />
				<Column>
					<DataUsage totalData={data?.data_total} usedData={data.data_used} />
					{column2Fields.map((field, index) => (
						<Layout gap="md" fullWidth nowrap key={index}>
							<Layout fullWidth nowrap style={{maxWidth: '120px'}}>
								<Text variant="body1" color="textSecondary">
									{field.label}
								</Text>
							</Layout>
							<Layout fullWidth nowrap>
								<Text variant="body1">{field.value}</Text>
							</Layout>
						</Layout>
					))}
					{
						isWarning && (
							<Layout gap="md" fullWidth nowrap>
								<Layout fullWidth nowrap style={{maxWidth: '120px'}}>
									<Text variant="body1" color="textSecondary">
										{t('Warning')}
									</Text>
								</Layout>
								<Layout fullWidth nowrap>
									<Text variant="body1" color="error">
										{data?.note}
									</Text>
								</Layout>
							</Layout>
						)
					}
				</Column>
			</Row>
			<TrustpilotDialog show={showTrustpilot} onClose={onHideTrustpilot} blankData={false}
			/>
		</UserInfo>
	);
};

UserInfoList.propTypes = {
	data: PropTypes.object,
};

export default UserInfoList;
