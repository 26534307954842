import React, {useCallback, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';

import {StyledDialog, StyledDialogBody} from './styled';

function TableDialog({show, children, onClose, width}) {
	const dialogRef = useRef(null);
	const handleClose = useCallback(() => {
		onClose();
	}, [onClose]);

	useEffect(() => {
		const handleKeyDown = (e) => {
			const isTrustpilotDialogOpen = document.getElementById('trustpilot-dialog');

			if (isTrustpilotDialogOpen && e.key === 'Escape') {
				e.stopPropagation();
				return;
			}
			if (e.key === 'Escape') {
				handleClose();
			}
		};
		window.addEventListener('keydown', handleKeyDown);
		return () => {
			window.removeEventListener('keydown', handleKeyDown);
		};
	}, [handleClose]);

	useEffect(() => {
		const handleClickOutside = (e) => {
			const isTrustpilotDialogOpen = document.getElementById('trustpilot-dialog');

			if (isTrustpilotDialogOpen) {
				e.stopPropagation();
				return;
			}
			if (dialogRef.current && !dialogRef.current.contains(e.target) && !isTrustpilotDialogOpen) {
				handleClose();
			}
		};
		window.addEventListener('mousedown', handleClickOutside);
		return () => {
			window.removeEventListener('mousedown', handleClickOutside);
		};
	}, [dialogRef, handleClose]);

	return (
		<StyledDialog
			show={show}
			width={width}
		>
			<StyledDialogBody
				fullHeight
				ref={dialogRef}
				fullWidth
				direction="column"
				nowrap
				gap="none"
			>
				{children}
			</StyledDialogBody>

		</StyledDialog>
	);
}

TableDialog.propTypes = {
	show: PropTypes.bool,
	children: PropTypes.node,
	onClose: PropTypes.func,
	width: PropTypes.number,
};

export default TableDialog;
