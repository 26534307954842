import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';

import {Button, Dialog, Layout, Text, TextField} from '@phonectas/unifon-ui-kit';
import useThreeSixtyStore from '../../stores/ThreeSixty';
import UnifonStarIcon from '../icons/UnifonStarIcon';
import TextWithLabel from '../TextWithLabel';
import {isEmailValid} from 'utils/email';

function TrustpilotDialog({onClose, show, blankData = true}) {
	const [t] = useTranslation();
	const {sendTrustPilotReview, fetchAccountFeed} = useThreeSixtyStore();
	const {user, account} = useThreeSixtyStore((state) => ({
		user: state.activeRow,
		account: state.activeAccount,
	}));

	const defaultTemplate = {
		receiver_mail: '',
		consumer_name: '',
		account_id: '',
		user_id: '',
	};
	const [temp, setTemp] = useState(defaultTemplate);
	const onInputChange = useCallback((e) => {
		setTemp((prev) => ({
			...prev,
			[e.target.ariaLabel]: e.target.value,
		}));
	}, []);

	const onReset = useCallback(() => {
		setTemp((prev) => ({
			...prev,
			receiver_mail: '',
			consumer_name: '',
			user_id: '',
		}));
	}, []);

	const handleSend = useCallback(() => {
		sendTrustPilotReview(temp, () => {
			fetchAccountFeed(account?.id);
		});
	}, [temp]);

	const disableCanSend = useCallback(() => {
		let canSend = true;
		if (isEmailValid(temp.receiver_mail) && temp.consumer_name && temp.account_id) {
			return canSend = false;
		}
		return canSend;
	}, [temp]);

	useEffect(() => {
		setTemp((prev) => ({
			...prev,
			account_id: account?.id,
		}));
	}, [account]);
	useEffect(() => {
		if (blankData) return;
		if (user) {
			if (user?.account_id === account?.id) {
				setTemp((prev) => ({
					...prev,
					receiver_mail: user?.email,
					consumer_name: user?.name,
					user_id: user?.id,
				}));
			} else {
				setTemp((prev) => ({
					...prev,
					receiver_mail: '',
					consumer_name: '',
					user_id: '',
				}));
			}
		}
	}, [user, account]);

	return (
		<Dialog
			id="trustpilot-dialog"
			onOk={handleSend}
			show={show}
			title={
				<Layout alignItems="center" gap="xs">
					<Text variant="h1">{t('Trustpilot')}</Text>
					<UnifonStarIcon />
				</Layout>
			}
			onClose={onClose}
			disableOk={!temp?.receiver_mail || !temp?.consumer_name}
			okButtonLabel={t('Send')}
			closeButtonLabel={t('Close')}
			customActions={
				<Layout fullWidth alignItems="center" justifyContent="space-between" nowrap>
					<Layout alignItems="center">
						<Button onClick={handleSend} variant="contained" color="success" disabled={disableCanSend()}>
							{t('Send')}
						</Button>
						<Button onClick={onClose} compact color="secondary">
							{t('Close')}
						</Button>
					</Layout>
					<Button onClick={onReset} variant="secondary" color="primary" disabled={!temp?.receiver_mail && !temp?.consumer_name}>
						{t('Clear')}
					</Button>
				</Layout>
			}
		>
			<Layout direction="column" gap="md">
				<Layout direction="column" style={{width: '380px'}} gap="sm">
					<TextWithLabel label={t('Customer')} text={account?.account_details?.name} />
					<Layout direction="row" fullWidth gap="sm">
						<TextField
							aria-label="receiver_mail"
							value={temp?.receiver_mail}
							label={t('Email')}
							onChange={onInputChange}
							fullWidth
							validator={(val) => isEmailValid(val)}
							errorMessagePlacement="none"
							inputProps={{autoFocus: true}}
						/>
					</Layout>
					<Layout direction="row" fullWidth gap="sm">
						<TextField
							aria-label="consumer_name"
							value={temp?.consumer_name}
							label={t('Name')}
							onChange={onInputChange}
							fullWidth
							validator={(val) => val !== ''}
							errorMessagePlacement="none"
						/>
					</Layout>
				</Layout>
			</Layout>
		</Dialog>
	);
}

TrustpilotDialog.propTypes = {
	id: PropTypes.string,
	onOk: PropTypes.func,
	onClose: PropTypes.func,
	show: PropTypes.bool,
	onChange: PropTypes.func,
	blankData: PropTypes.bool,
};

export default TrustpilotDialog;
