/* eslint-disable max-len */
import React from 'react';

function UnifonStarIcon(props) {
	return (
		<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M15.82 12.8951L18.2828 19.961L11.8352 15.594L12.767 14.9195L14.3465 13.856L15.82 12.8951Z" fill="#2C305E"/>
			<path d="M10.5 0L12.9787 7.6287H21L14.5106 12.3435L12.5314 13.8667L10.5 15.2574L4.01064 19.9722L6.48936 12.3435L0 7.6287H8.02129L10.5 0Z" fill="#2C305E"/>
		</svg>
	);
}

export default UnifonStarIcon;
