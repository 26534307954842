import BnCall from './CustomerEvents/components/eventTypes/BnCall';
import ErateSystemCall from './CustomerEvents/components/eventTypes/ErateSystemCall';
import EventTypeSms from './CustomerEvents/components/eventTypes/EventTypeSms';
import ThreeSixtyComments from './CustomerEvents/components/eventTypes/ThreeSixtyComments';
import ThreeSixtyNewCustomerResp from './CustomerEvents/components/eventTypes/ThreeSixtyNewCustomerResp';
import ThreeSixtyNewSaf from './CustomerEvents/components/eventTypes/ThreeSixtyNewSaf';
import ThreeSixtyTrustpilot from './CustomerEvents/components/eventTypes/ThreeSixtyTrustpilot';
import UnknowEvent from './CustomerEvents/components/eventTypes/UnknowEvent';

// Add more cases as needed
function getEventType(eventType) {
	switch (eventType) {
	case 'THREESIXTY_COMMENT':
		return ThreeSixtyComments;
	case 'THREESIXTY_NEW_CUSTOMER_RESP':
		return ThreeSixtyNewCustomerResp;
	case 'BN_CALL_LOG':
		return BnCall;
	case 'ERATE_CALL_LOG':
		return ErateSystemCall;
	case 'ERATE_SYSTEM_SMS':
		return EventTypeSms;
	case 'THREESIXTY_NEW_SAF_VALUE':
		return ThreeSixtyNewSaf;
	case 'THREESIXTY_OUTGOING_SMS':
		return EventTypeSms;
	case 'THREESIXTY_OUTGOING_TRUSTPILOT':
		return ThreeSixtyTrustpilot;
	default:
		return UnknowEvent;
	}
}

export default getEventType;
