import {Layout} from '@phonectas/unifon-ui-kit';
import sc from 'styled-components';

export const UserInfo = sc.div`
  display: flex;
  width: 100%;
`;

export const Row = sc.div`
    display: flex;
    flex-wrap: nowrap;
    gap: 20px;
    width: 100%;
`;

export const Column = sc.div`
    display: flex;
    gap: 5px;
    flex: 1;
    width: 100%;
    min-width: 300px;
    padding: 10px 0px;
    flex-wrap: wrap;
    flex-direction: column;
`;

export const StyledStarLayout = sc(Layout)`
  align-items: center;
  border: solid 1px #2C305E;
  border-radius: 5px;
  cursor: pointer;
  padding: 0px 5px;
  &:hover {
	background-color:rgb(24, 114, 21);
  }
`;

export const StyledStarIconWrapper = sc(Layout)`
  justify-content: center;
  align-items: top;
  height: 20px;
  width: 24px;
`;
